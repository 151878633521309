/** @jsx jsx */
import { jsx, Input, Text } from "theme-ui";
import { graphql } from "gatsby";

import {
  Card,
  Heading,
  Layout,
  PortableText,
  ProtectedImage,
} from "components";
import { getArtistName, getArtworkPath } from "utils";
import { Fragment, useState } from "react";

function PrivateRoom({
  data: {
    sanityPrivateRoom: {
      title,
      content,
      artworks,
      display_thumbnails,
      display_large_first_image,
    },
  },
  pageContext: { encryptedPassword },
}) {
  const [password, setPassword] = useState("");

  const _btoa = (typeof window !== "undefined" && window?.btoa) || ((a) => a);

  if (_btoa(password) !== encryptedPassword) {
    return (
      <Layout pageTitle="Sign In">
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "300px",
          }}
        >
          <Text>Please enter password to view this private room</Text>
          <Input
            type="password"
            id="forename"
            autofillBackgroundColor="#f3f3f3"
            value={password}
            onChange={(evt) => setPassword(evt.currentTarget.value)}
            sx={{ maxWidth: "300px" }}
            autoFocus={true}
          />
        </div>
      </Layout>
    );
  }

  const [firstArtwork, ...restArtworks] = artworks;

  return (
    <Layout pageTitle={title} artworks={artworks}>
      {({ openArtworkModal }) => {
        const handleArtworkClick = (artwork) => (evt) => {
          evt.preventDefault();
          openArtworkModal({ artworkId: artwork._id });
        };

        return (
          <Fragment>
            <Heading mb="m">{title}</Heading>

            <div
              sx={{
                mb: "l",
                display: "flex",
                flexDirection: ["column", "row-reverse"],
                width: "100%",
              }}
            >
              {display_large_first_image && firstArtwork && (
                <div
                  sx={{
                    ml: [null, "l"],
                    mb: ["l"],
                    width: [null, "400px", "600px"],
                    flexGrow: 0,
                    flexShrink: 0,
                  }}
                >
                  <div
                    onClick={handleArtworkClick(firstArtwork)}
                    sx={{ mb: "xxs", cursor: "pointer" }}
                  >
                    <ProtectedImage
                      loading="eager"
                      image={
                        firstArtwork.work_image__large.asset.gatsbyImageData
                      }
                      alt={title}
                    />
                  </div>
                  <Text as="p">
                    {[
                      getArtistName(firstArtwork.artist),
                      firstArtwork?.work_title || firstArtwork?.name,
                      firstArtwork?.work_date,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </Text>
                </div>
              )}

              <div sx={{ flexGrow: 1, flexShrink: 1 }}>
                {content && <PortableText content={content} />}
              </div>
            </div>

            {display_thumbnails && (
              <div
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  mx: "-s",
                  clear: "both",
                }}
              >
                {(display_large_first_image ? restArtworks : artworks).map(
                  (artwork) => (
                    <div key={artwork._id} sx={{ m: "m", mt: "s" }}>
                      <Card
                        to={getArtworkPath(artwork)}
                        gatsbyImageData={
                          artwork?.work_image?.asset?.gatsbyImageData
                        }
                        imageAlt={artwork.name}
                        onClick={handleArtworkClick(artwork)}
                        title={getArtistName(artwork.artist)}
                        subTitle={[artwork.name, artwork.work_date]
                          .filter(Boolean)
                          .join(", ")}
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </Fragment>
        );
      }}
    </Layout>
  );
}

export const query = graphql`
  query PrivateRoom($id: String) {
    sanityPrivateRoom(_id: { eq: $id }) {
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      artworks {
        _id
        name
        medium {
          medium
        }
        work_image {
          asset {
            gatsbyImageData(height: 220)
          }
        }
        work_image__large: work_image {
          asset {
            gatsbyImageData(height: 440)
          }
        }
        image: work_image {
          asset {
            gatsbyImageData(height: 800)
          }
        }
        image__short: work_image {
          asset {
            gatsbyImageData(height: 500)
          }
        }
        work_title
        artist {
          _id
          first_name
          last_name
          yearOfDeath: date_of_death(formatString: "yyyy")
          yearOfBirth: date_of_birth(formatString: "yyyy")
        }
        work_date
        content: _rawDetails(resolveReferences: { maxDepth: 10 })
      }
      display_thumbnails
      display_large_first_image
    }
  }
`;

export default PrivateRoom;
